<template>
  <div class="exercise-list-page w-100">
    <Wrapper>
      <b-row>
        <b-col :cols="3">
          <basic-select
            label="Đối tác"
            placeholder="Chọn đối tác"
            :options="agencies"
            track-by="id"
            value-label="name"
            :value.sync="filter.agencyId"
            @update:value="handleChangeAgency()"
          ></basic-select>
        </b-col>
        <b-col :cols="3">
          <basic-select
            label="Người giới thiệu"
            placeholder="Chọn người giới thiệu"
            :options="presenterList"
            :value.sync="filter.presenterId"
            @update:value="loadData()"
            track-by="id"
            value-label="fullName"
          ></basic-select>
        </b-col>
        <b-col :cols="3">
          <b-form-group id="createdtime">
            <label for="createdtime">Ngày tạo tài khoản</label>
            <date-picker
              :range="true"
              :clearable="false"
              input-class="form-control"
              type="date"
              format="DD/MM/YYYY"
              value-type="date"
              @change="handleChangeCreateDatetime"
              v-model="filter.createDatetime"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="3">
          <basic-input
            label="Tìm kiếm"
            placeholder="Tìm theo sđt"
            :debounce="2000"
            :value.sync="filter.phone"
            @v-modal:update="searchDebounce"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="12">
          <template-table
            :column="column"
            :data="data"
            :paging="paging"
            :tableAction="false"
            :selectAction="false"
            :searchAction="false"
            @sortBy="sortRequest"
          >
            <template v-slot:body="{ item, index }">
              <td>{{ index + 1 }}</td>
              <td>{{ item.partnerName }}</td>
              <td>{{ item.phoneNumber }}</td>
              <td>{{ item.fullName }}</td>
              <td>{{ formatDate(item.createDatetime) }}</td>
              <td>{{ item.referalCode }}</td>
              <td>{{ item.presenter }}</td>
            </template>
          </template-table>
        </b-col>
      </b-row>
    </Wrapper>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
import _ from 'lodash';
export default {
  name: 'trackingShareApp',
  components: {
    'date-picker': DatePicker,
  },
  data() {
    return {
      column: [
        {
          key: 'stt',
          label: 'Stt',
        },
        {
          key: 'partnerName',
          label: 'Đối tác',
        },
        {
          key: 'phoneNumber',
          label: 'Số điện thoại đăng ký',
        },
        {
          key: 'fullName',
          label: 'Họ và Tên',
        },
        {
          key: 'createDatetime',
          label: 'Ngày tạo tài khoản',
        },
        {
          key: 'referalCode',
          label: 'Mã giới thiệu',
        },
        {
          key: 'presenter',
          label: 'Người giới thiệu',
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      data: [],
      agencies: [],
      presenterList: [],
      masterPresenter: [],
      filter: {
        createDatetime: [moment().toDate(), moment().add(1, 'months').toDate()],
        agencyId: null,
        presenterId: null,
        phone: null,
      },
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        startDate: moment(this.filter.createDatetime[0]).add(1, 'd').toDate(),
        dueDate: moment(this.filter.createDatetime[1]).add(1, 'd').toDate(),
        agencyId: this.filter.agencyId,
        presenterId: this.filter.presenterId,
        phone: this.filter.phone,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  created() {
    this.getSearchDataPrepare();
  },
  methods: {
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('/TrackingShareApp', {
          params: this.searchParams,
        })
        .then((res) => {
          this.data = res.items ?? [];
          this.paging.total = res.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    handleChangeCreateDatetime() {
      this.loadData();
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'YYYY/MM/DD HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    getSearchDataPrepare() {
      this.$api.get('/TrackingShareApp/PrepareFormItemAsync').then((res) => {
        console.log(res);
        this.agencies = res?.agencyList ?? [];
        this.presenterList = res?.presenterList ?? [];
        this.masterPresenter = res?.presenterList ?? [];
      });
    },
    handleChangeAgency() {
      this.filter.presenterId = null;
      if (this.filter.agencyId) {
        this.presenterList = _.filter(this.masterPresenter, (item) => {
          return (
            item.tags &&
            _.some(item.tags, (tag) => {
              return tag === this.filter.agencyId;
            })
          );
        });
      } else {
        this.presenterList = this.masterPresenter;
      }
      this.loadData();
    },
    searchDebounce(value) {
      if (value) {
        this.filter.phone = value;
        this.loadData();
      }
    },
  },
};
</script>
